// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCbHahLb54kXtdBKMtnse4eVa-QzqFIDkQ",
  authDomain: "jminsurence-f8c67.firebaseapp.com",
  projectId: "jminsurence-f8c67",
  storageBucket: "jminsurence-f8c67.appspot.com",
  messagingSenderId: "570305302060",
  appId: "1:570305302060:web:a8b6d8c0e772c931c24b89"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc };
