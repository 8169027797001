import React from 'react';
import './About.css';
import image from './images/about.jpg'; // Replace with your image path

const About = () => {
  return (
    <section className="about">
      <div className="about-container">
        <div className="about-content">
          <div className="text-content">
            <h2>About Us</h2>
            <p>
              We, JM and Associate Joint Venture of Thronepe Communications Pvt Ltd, situated at Noida, Uttar Pradesh,
              are one of the leading firms in the country, set up to cater to the growing requirements in all sectors.
            </p>
            <p>
              We have strengthened the business of the customers through proactive product development, timely delivery, 
              and superior product attributes by reinforcing innovation, cost leadership, and premium quality.
            </p>
          </div>
        </div>
        <div className="about-image-container">
          <img src={image} alt="Insurance" className="about-image" />
        </div>
      </div>
    </section>
  );
};

export default About;
