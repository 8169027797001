import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Carasoul.css'; // Import custom CSS
import image1 from './images/carasoual1.jpg'; 
import image2 from './images/carasoual2.jpg';
import image3 from './images/carasoual3.jpg';

function Carasoul() {
  return (
    <Carousel className="custom-carousel" data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100 carousel-image"
          src={image1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Testimonials</h5>
          <p>I totally recommend them. they are best</p>
          <h5>Neha Kapoor</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousel-image"
          src={image2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Testimonials</h5>
          <p>The offer everything excellent quality. Really appreciated</p>
          <h5>Pankaj jha</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 carousel-image"
          src={image3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Testimonials</h5>
          <p>The offer everything excellent quality. Really appreciated</p>
          <h5>Shirish Mehra</h5>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carasoul;
