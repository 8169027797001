import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>TERMS AND CONDITIONS OF INSURANCE POLICIES</h1>
      <p>
      The general terms and conditions of insurance contracts set out the definitions used in insurance contracts, also the rights and obligations of the insurer, policyholder and the insured person upon conclusion and performance of a contract.
 
 The general terms and conditions shall apply to all medical insurance products offered by the Estonian branch of Insurance Company SE insofar as the general terms and conditions are not in conflict with the special terms and conditions of insurance.
  
  
 1. Definitions used in the general terms and conditions
  
 1.1. Insurer is Insurance Company SE’s Estonian branch.
 1.2. Policyholder is a person who has an insurable interest and who has concluded an insurance contract with the insurer.
 1.3. Insurable interest is the interest of the policyholder to insure his/her life and/or health or that of the insured person against a specific insurable risk.
 1.4. Insured person is a natural person specified in the insurance contract, in whose favour the insurance contract is concluded.
 1.5. Beneficiary is a person agreed upon in the insurance contract, who is entitled to the insurance indemnity upon occurrence of an insured event.
 1.6. Insured risk is an event that is independent from the inten- tion of the insured person and is specified in the insurance contract, the arrival of which in the future is probable.
 1.7. Insured event is an event independent from the intention of the insured person, which is in causal relationship with the insured risk, upon the arrival of which insurance indemnity shall be paid in accordance with the conditions of the insur- ance contract.
 1.8. The waiting period is a period of time starting from signing the insurance contract, wherein insurance compensation will not be paid for insurance events occurring during that period. The fixed dates of the waiting period are stated in the insur- ance contract. For accidents, no waiting period is applied.
 1.9. Accident is an unexpected and unforeseeable event that takes place against the free will of the insured person, as re- sult of which an external and/or violent force causes damage to health of the insured person.
 1.10. The deductible is an amount or percentage, agreed upon in the insurance contract, of the costs paid for health care services which must be covered by the policyholder or the insured person in case of an insured event.
 1.11. Insurance application is a document in the form established by the insurer, which the policyholder shall submit to the insurer, notifying the latter of significant circumstances necessary for evaluation of probability of materialization of the insured risk.
 1.12. Insurance offer is a proposal of the insurer to conclude an insurance contract.
 1.13. Insurance contract is an agreement concluded between the insurer and policyholder. The insurance contract consists of the following parts:
 • insurance application;
 • policy:
 • special terms and conditions of insurance (if agreed upon);
 • terms and conditions of insurance
 • amendments and supplements to the insurance contract.
 1.14. Terms and conditions of insurance are conditions that the insurer applies to a specific insurance relationship. Terms and conditions of insurance are deemed to include these general terms and conditions, the policy conditions of each product, and special conditions.
 1.15. 
 Policy is a document issued by the insurer, which certifies conclusion of the insurance contract. The certification by the representative of the insurer on the policy may be original, digital or replicated.
 1.16. Payment notice is the information sent by the insurer, which notifies the policyholder of the imminent payment deadline.
 1.17. Insurance period is a time period on the basis of which in- surance premiums are calculated. The duration of the insur- ance period shall be one year, unless stated otherwise in the insurance contract.
 1.18. Insurance cover is the obligation of the insurer, upon occur- rence of an insured event, to pay the sum insured or insurance indemnity or to perform the contractual obligation in any other manner agreed upon.
 1.19. Sum insured is the amount established in the insurance con- tract in which the insurer shall pay the insurance indemnity upon an insured event.
 1.20. Medical institution is a medical practice that provides health services, a public or municipal institution or a company en- tered in the register of Health Board, which provides health services in conformity with the requirements of legislation.
 1.21. Indemnity application is a document that is in a form estab- lished by the insurer, which the person insured shall submit to the insurer for receiving insurance indemnity on the basis of the signed insurance contract. The application is available on the insurer’s website www.Insurance Company.ee.
 1.22. Health services are the health services that are provided to the insured persons at a medical institution.
 1.23. Written notification is the sending of a notice, application or other information to the insurer, policyholder or any other person agreed upon, in a manner that allows for reproduction of the forwarded information. Notification, application, or other information shall be submitted on the postal address or e-mail address indicated in the insurance contract documentation. Information is deemed to be delivered if sent by a notice, appli- cation or other communication in the aforementioned manner.
  
  
 2. Entry into force, the term of the insurance contract, and payment of the insurance premium
  
 2.1. An insurance contract shall be concluded on the basis of an insurance application.
 2.2. An insurance contract is deemed to be concluded if the poli- cyholder has met the following conditions:
 • has confirmed the conclusion of the insurance contract with his/her signature;
 • has paid the insurer the first insurance premium;
 • has committed any other act agreed upon in the insurance contract.
 2.3. The insurance contract is concluded without a term. The du- ration of insurance period shall be one year, unless stated otherwise in the insurance contract. The insurer shall issue a new insurance policy for each insurance period unless oth- erwise agreed upon in the insurance contract.
 2.4. An insurance contract may be concluded for a fixed term if it is related to training, a stay in a foreign country, traveling, or the performance of a fixed-term work or operation.
 2.5. If the insured person is not a policyholder, an insurance contract can be concluded only upon the consent of the insured per- son. If the policyholder insures his/her child who is under his/ her custody and has not attained 18 years of age by the time of conclusion of the insurance contract, the insurance application shall be signed by the policyholder on behalf of the child.
  
 1/5
  
 2.6. Insurance cover is valid only for health services provided in Estonia. Costs for health services provided outside Estonia shall be compensated only if specified so in the insurance contract. Insurance cover shall be valid 24 hours a day.
 2.7. The policyholder has the right to designate a third person as a beneficiary and change that person. As regards medical costs, the beneficiary is (a) the insured person if he/she paid the medical costs, or (b) the medical institution that provided the health service agreed upon.
 2.8. If the beneficiary dies or a legal person beneficiary ends its activities before the insured event, the insurer shall perform its obligation towards the inheritors of the insured person, unless stated otherwise by the insured person before the insured event.
 2.9. The insurance premium is deemed to be paid when the amount is credited to the bank account of the insurer or is paid to the representative of the insurer in cash or by payment card.
 2.10. Delay in payment or failure to pay the first insurance premium:
 2.10.1. If the policyholder has not paid the insurance premi- um or the first insurance premium within 14 days after conclusion of the insurance contract, the insurer may withdraw from the contract until the payment is made.
 2.10.2. It is assumed that the insurer has withdrawn from the contract if the insurer does not file a claim for collec- tion of the insurance premium within three months as of the time when the premium becomes collectible.
 2.10.3. If the insurance premium that has become collectible or the first insurance premium has not been paid by the time when the insured event takes place, the insur- er shall be released from its obligation of performance.
 2.11. If the policyholder has failed to pay the second or a consec- utive insurance premium by the due date, the insurer may send a respective written notice to the policyholder, wherein the insurer provides a term of at least two weeks for the pay- ment to be made and announces the legal consequences of missing the payment term. If the policyholder pays their in- surance premium within one month as of the cancellation of the contract or expiry of the due date and no insured event has taken place before the payment, the contract shall not be deemed cancelled.
 2.12. The insurer shall submit a payment notice to notify the policy- holder of the imminent due date, bank account of the insurer and the reference number. The payment notice may be sent on paper or electronically.
 2.13. If the insurer does not send the invoice or the policyholder does not receive the invoice, the policyholder shall not be exempt from the obligation to pay the insurance premium.
 2.14. If the insurance premium is paid incorrectly and, on the basis of the information available, the insurer is not able to deter- mine the insurance contract for which the premium is paid, the premium is deemed to be unpaid until the insurance contract for which the premium is paid is identified.
 2.15. If the policyholder pays less than prescribed, the insurer shall contact him/her. The insurance premium is deemed to be re- ceived only when the entire prescribed amount is received.
 2.16. If the policyholder pays an amount that is larger than pre- scribed, it shall be refunded on the basis of an application from the policyholder.
  
  
 3. Change of insurance premium and insurance cover
  
 3.1. Following conclusion of the insurance contract, the insurer may change the insurance premium or change the insurance coverage, including establishing or changing the deductible in the following cases:
 3.1.1. a change of a circumstance that is beyond the con- trol of the parties and is specified in the insurance contract as the basis for calculating the insurance premium, for example, a change in the costs that are connected with the operation of the insurer and are included in the overheads;
 3.1.2. a change in the average age of the insured persons;
 3.1.3. a change in the frequency of insured events;
 3.1.4. a change in the degree of national compensation for sickness insurance service (if the degree of national compensation declines, the obligation of the insurer increases and, as a result thereof, it is substantiated to increase the insurance premium or change the in- surance cover);
 3.1.5. 
 a change in the fees for health services (if the fees of the health care provider rise, the performance obliga- tion of the insurer also changes and, as a result there- of, an increase in the insurance premium or change in the insurance cover is substantiated);
 3.1.6. amendment of legislation changing the health care administration (for example, if the performance obli- gation of the insurer increases due to amendments to the legislation).
 3.2. The insurer has the right to increase the initial premium, start- ing from a certain age of the insured person, up to an amount that the insurance rate prescribes for an insured person who concludes an insurance contract at a corresponding age. In each following insurance period, the insurance premium will increase by an amount equal to an increase in the premium rate of a person who concluded the insurance contract.
 3.3. The insurer shall notify the policyholder of changes to the in- surance contract at least one month in advance before entry into force of the changes.
  
  
 4. Termination of contract and payment of insurance premium balance
  
 4.1. The policyholder may cancel the medical insurance contract by giving at least one month notice of cancellation, taking into account that the contract expires upon expiry of the in- surance period.
 4.2. The insurer has the right to cancel a health insurance contract concluded for a shorter period than one year by giving at least three days’ notice thereof.
 4.3. The insurer has the right to ordinarily cancel a health insur- ance contract within the first three years by giving notice thereof one month in advance.
 4.4. If the insurer increases the premium or excess or reduces its obligations, the policyholder may cancel the contract within one month as of receipt of the notice of change. In such case, the insurance contract shall expire upon entry into force of the increase of insurance premium or the reduction of obligations.
 4.5. The policyholder may withdraw from the insurance contract within 14 days as of conclusion of the contract. To this end, the policyholder shall submit a written withdrawal application to the insurer. If the policyholder withdraws from the contract, the insurer will refund the insurance premiums paid by the policyholder, subtracting administration costs according to the applicable price list.
 4.6. Upon cancellation of and withdrawal from the contract, the policyholder has the right for refund of the premium paid for the remaining insurance period, from which the insurer may deduct 25% for administrative costs. Upon refunding the pre- mium, account shall be taken, inter alia, of the degree to which the insurer already has or is about to have the obligation to pay indemnity.
  
  
 5. Obligations of the insured person and the policyholder. Notification obligation of insurer
  
 5.1. Obligations of the insured person and the policyholder:
 5.1.1. the policyholder shall pay insurance premiums;
 5.1.2. the policyholder and insured persons are required to perform the obligations set out in the insurance con- tract;
 5.1.3. upon conclusion of a contract, the policyholder and insured person shall notify the insurer of all circum- stances known to them which have an impact, due to the nature thereof, on the insurer’s decision to con- clude a contract or do so on the conditions agreed up- on (significant circumstances). A circumstance is con- sidered significant if the insurer has directly requested information in a format that can be reproduced in writ- ing. If the policyholder or insured person has not noti- fied the insurer upon conclusion of the insurance con- tract of all significant circumstances known to them, or if they have intentionally avoided a significant cir- cumstance becoming known or have supplied incor- rect information with regard thereof, the insurer may either withdraw from the contract or demand a higher
  
 1/5
  
 premium from the policyholder, pursuant to the leg- islation. The insurer may exercise such right within three years as of conclusion of the contract;
 5.1.4. the insured person is required to make all efforts for restoring his/her health and comply with the prescrip- tions of a medical specialist;
 5.1.5. the policyholder or insured person is required to im- mediately notify the insurer of an increase in the like- lihood of insurable risk, for example, if the occupation or area of activity of the insured changes, the insured starts to pursue high-risk hobbies or activities, or if the insured person enters the active service of the Defence Forces. If the new area of activity, profes- sion, hobby, etc., of the insured person is subject to a higher insurance premium than before the change in the insured risk pursuant to the applicable rates, the insurer has the right to change the premium by giving the policyholder at least one month’s notice of the new amount of the insurance premium.
 5.2. Obligations of the policyholder and insured person upon oc- currence of an insured event:
 5.2.1. the policyholder and insured person shall notify the insurer of each insured event in writing at the first available opportunity, apply all measures to identify the circumstances of the insured event and follow the instructions of the insurer, if the circumstances allow for this;
 5.2.2. the policyholder and insured person are required to help the insurer identify the circumstances of the in- sured event and to submit additional documents as requested by the insurer, which certify occurrence, place, date and time of the insured event (this applies to originals of all certificates, invoices and payment documents);
 5.2.3. the insured person is required to turn to a licensed physician or medical institution, follow the physician’s instructions and, if in-patient treatment is prescribed for the insured person, immediately notify the insur- er thereof;
 5.2.4. upon an insured event, the insured person is required to submit to a medical institution or physician a health insurance card issued by the insurer. If the insured person turns to a medical institution for immediate medical care and the institution has no cooperation agreement with the insurer, the insurer shall be in- formed thereof at the first possibility;
 5.2.5 at the request of the insurer, the insured person shall pass a health check as determined by the insurer.
 5.3. Notification obligation of insurer:
 During validity of the insurance contract, the insurer is required to inform the policyholder of any change in the name, legal form, address of the insurer, as well as the address of the insurance supervision authority or its office where the insurance contract is concluded. Policyholders shall be notified of the aforemen- tioned changes via the insurer’s website www.Insurance Company.ee or media.
  
  
 6. Decision on payment of insurance indemnity or refusal to make the payment, takeover of the right of claim
 6.1. The insurer shall make a decision on the payment or non-pay- ment of the indemnity within 10 days as of the receipt of all necessary documents.
 6.2. The insured person’s right of claim against a person liable for damage shall transfer to the insurer to the extent of paid insurance indemnity (right of recourse).
 6.3. If the right of recourse in favour of the insurer is not partially or fully created due to the activities or omissions of the person insured or the policyholder, the insurer has the right to reduce the indemnity accordingly or demand a refund of the paid in- demnity to the extent of that which cannot be recovered.
 6.4. The insurer has the right to withhold from the indemnity the amount of excess set out in the insurance contract and the part of the insurance premium not paid for the insurance pe- riod, which corresponds to the percentage of the sum insured in which the insurer has performed the obligation.
 6.5. 
 After payment of insurance indemnity, the sum insured shall decline by the amount of paid indemnity.
 6.6. The insurer has the right to send information concerning the adopted decision to the insured person electronically by using the known communication channels of the insured person, including e-mail address. If the insured person also wants to receive the decision in addition to the aforementioned infor- mation, he/she shall notify the insurer thereof and give the address to which the decision should be sent. A notice of payment of insurance indemnity may also be sent via other communication channels specified in the application of the insured person.
 6.7. Within 10 working days from receiving the indemnity applica- tion, the insurer shall to notify the insured person in writing as to which additional documents are necessary for making a decision on payment of indemnity.
 6.8. If the documents submitted for receiving indemnity are incom- plete, are incorrectly filled out and/or additional time is needed for verifying the insured event or submitted documents, the insurer has the right to postpone the adoption of a decision for up to one month until receiving the necessary information.
 6.9. Together with the benefit application, the insured person shall submit to the insurer the original invoice for the health services and upon request of the insurer also a document confirming the payment of the invoice.
 6.10. In addition, an extract of the medical history or medical records shall be presented to the insurer. An extract together with the data concerning the insured event shall be issued by the med- ical institution or the doctor who provided the medical care.
 6.11. If the insured person turns to a medical institution or physi- cian who is not a cooperation partner of the insurer, he/she shall be compensated for the cost of service on the basis of the average market price unless otherwise specified in the insurance contract.
 6.12. Within 14 days as of the end of the insurance period, the in- surer shall compensate for expenses incurred after the end of the insurance period if the insured event lasts longer than the term of the insurance contract.
 6.13. The indemnity shall be paid to a medical institution or physi- cian on the basis of the treatment bills submitted by them in accordance with the conditions of the agreement concluded between the insurer and medical institution or the physician.
 6.14. If continuing with the treatment is medically not substantiated, the insurer may reduce the indemnity accordingly or refuse to pay the indemnity.
 6.15. The insurer shall have the right to deduct premiums stated on the insurance policy from the indemnity if they are not paid for the respective insurance period.
  
  
 7. General exclusions and release of insurer from the obligation to perform contract
  
 7.1. The insured event shall not include the following events or any damage created as a result thereof:
 7.1.1. damage that is directly or indirectly caused by a ter- rorist act or the preparation thereof. A terrorist act is understood as organised violence or a threat to use violence for political, religious, ideological or eth- nic purposes by a person or a group of persons who act in the name of organisation(s) or government(s), according to their instructions or in cooperation with them, in order to influence the government and/or threaten society or any part thereof;
 7.1.2. damage caused in connection with events like strike, uprising, civil unrest, acts of civil disobedience, riot, armed clashes, civil disturbances, revolution, military coup d’état, usurpation of military power, war, civil war, state of war, military activities, invasion, acts of foreign enemies, acts of state and local authorities, amend- ments to laws and other legislative acts, orders of the government, natural disaster, pandemics, other natural catastrophes, epidemics (large-scale spread of infec- tious diseases of which a state authority has notified);
 7.1.3. damage caused by direct or indirect effect of nucle- ar energy or radioactive radiation, electromagnetic, light or thermal radiation, as a result of radiation poi- soning or radioactive contamination;
  
 1/5
  
 7.1.4. damage caused by participation in an act punishable as a criminal offense by an attempt to commit such an act or committing thereof, lawful detention of offend- ers or during a stay at a custodial institution;
 7.1.5. damage intentionally caused by the policyholder or insured person;
 7.1.6. medical costs related to illness or trauma that oc- curred before the entry into force of the insurance contract, except when agreed otherwise in the insur- ance contract;
 7.1.7. damage that did not take place on the territory agreed upon in the insurance contract;
 7.1.8. accident caused by mental, psychological or con- sciousness disorders of the insured person;
 7.1.9. damage caused by the insured person as a result of using alcoholic, narcotic or other intoxicating sub- stances, or due to diagnostics or treatment of a health disorder resulting from the use of alcoholic, narcotic or other intoxicating substances, psychological dis- eases or their syndromes, also as a result of treat- ment of alcoholism, drug addiction, substance de- pendence, sexually transmitted diseases, etc.;
 7.1.10. damage resulting from treatment which was not nec- essary for direct treatment of the disease, such as cosmetic procedures, cosmetic services and/or aes- thetic enhancement, cosmetic surgery;
 7.1.11. damage caused by the continuous use of medical de- vices, such as spectacles, lenses, prosthetic appli- ances, hearing aid, wheelchair, crutches;
 7.1.12. non-traditional diagnostics and/or treatment or self-treatment, participation in closed clinical trials;
 7.1.13. costs from which the insured person is relieved under valid regulations;
 7.1.14. use of services provided without medical indication or if the insured person refused medical assistance or surgery, and therefore further damage was caused to his/her life or health;
 7.1.15. damage caused in connection with participation of the insured person in the international operations of the Estonian Defence Forces, enrolment or participation in the active service, including service in the National Defence League, participation in military operations and training;
 7.1.16. damage caused by driving a vehicle without the right to drive a vehicle of the corresponding category and/ or substantially violating the requirements set out in the applicable Traffic Act;
 7.1.17. expenses incurred due to traumas or bodily injuries, received as a result of engagement in high-risk sports or hobbies, also competitive sports and relevant train- ing. High-risk sports or hobbies include motor sports, including participating as a passenger in motor ve- hicle races and test drives, bungee jumps, boxing, mountaineering, downhill racing, flying aircraft with- out an engine, skydiving and other extreme sports, any professional sports and other sports and hobbies involving similar risks;
 7.1.18. damage caused during professional flights as a pilot of an aircraft or any other member of the cabin crew;
 7.1.19. damage caused mainly by sexually transmitted dis- eases (syphilis, gonococcal infections, venereal chlamydia granuloma and other chlamydia diseas- es, chancroid, klebsiella granulomatis, donovanosis, trichomoniasis, anogenital herpes virus infections), during treatment or diagnostics of AIDS and HIV;
 7.1.20. costs relating to sexual pathology, its treatment and diagnostics;
 7.1.21. costs related to genetic and cytogenetic studies, ex- cept when related to an insured event;
 7.1.22. psychical or mental diseases and diagnostics and treatment of their syndromes;
 7.1.23. costs related to speech therapy, Insurance Companytherapy or sleep therapy;
 7.1.24. costs of osteoporosis studies;
 7.1.25. costs related to family planning, contraceptives, in- fertility treatment, assisted reproductive techniques, premature termination of pregnancy without medical indication;
 7.1.26. 
 costs related to transplantation of organs and tissues or haemodialysis upon chronic renal insufficiency ex- cept when agreed otherwise in the insurance con- tract;
 7.1.27. costs of food supplements, dietary cocktails and spe- cial food, photodynamic laser treatment and informa- tive lectures;
 7.1.28. stay at a sanatorium and treatment provided there, accommodation at a sanatorium or spa for the pur- pose of nursing;
 7.1.29. costs related to trichology or podometry;
 7.1.30. treatment services provided by a geneticist;
 7.1.31. treatment provided by a medical institution, physicist or nurse that is not registered in the registry of the Health Board;
 7.1.32. medical care provided by a physicist or nurse who is a close relative to the insured person (child, parent, sister, brother, spouse) (except emergency care);
 7.1.33. damage and medical costs that are compensated by compulsory insurance (e.g. traffic insurance) pay- ments;
 7.1.34. ambulance call-out and transportation of the person by ambulance to a hospital;
 7.1.35. treatment received by family members of the insured person who are not named in the insurance contract;
 7.1.36. additional costs related to selection of a doctor need- ed to perform a surgery;
 7.1.37. nursing care costs supported from social assistance, ex- cept when agreed otherwise in the insurance contract;
 7.1.38. suicide attempt and expenses related to suicide.
 7.2. The insurer shall be partially or fully released from the obliga- tion to perform the insurance contract, if:
 7.2.1. The policyholder, insured person, or beneficiary has violated the obligations set out in the insurance con- tract;
 7.2.2. the policyholder, insured person or beneficiary has, whether intentionally or due to gross negligence (fail- ure to apply due diligence upon performance of a contractual obligation), violated at least one of the conditions of the insurance contract, which has an impact on the occurrence of an insured event or the amount of damage;
 7.2.3. the policyholder, insured person or beneficiary has knowingly supplied incorrect or incomplete data upon conclusion of an insurance contract or damage han- dling;
 7.2.4 an insured event has occurred due to gross negli- gence or intent on behalf of the policyholder or the insured person. Gross negligence is understood as a situation where the person foresaw or should have foreseen the consequences of his/her behaviour, but recklessly expects that no consequences will arrive due to his/her behaviour or any other circumstances.
  
  
 8. Processing of personal data and protection of data submitted to the insurer, including confidentiality
  
 8.1. The insurer has the right to process the personal data of the policyholder and insured person, without their consent (except for sensitive personal data), for performing the insurance con- tract concluded in favour of the policyholder or for securing the performance of the contract, assessing the insurable risk or for other procedures preceding conclusion of the contract and issuance of the policy in case if the policyholder has sub- mitted an application for conclusion of the insurance contract and conclusion of the contract requires performance of those procedure(s).
 8.2. The insured person agrees that, in the case and for the pur- pose specified in clause 7.1, the insurer shall also processes the sensitive personal data of a client (information concerning the health condition or disability of the client).
  
 1/5
  
 8.3. The insurer has the right to preserve the personal data for se- curing performance of the contract until the term of expiry of a claim arising from the contract, unless otherwise stipulated in the legislation.
 8.4. Upon an insured event, a third party may, without the consent of the insured person, forward the personal data to the insurer or enable access to personal data which is necessary for the insurer to determine the obligation to perform the insurance contract or the extent of such performance. The above also applies to the information concerning the health condition or disability of the insured person, if it is necessary to the insurer for performing the contract, securing the performance of the contract or for determining the obligation of performance and its extent. The insurer shall strictly adhere to the requirements of the Act on Processing of Personal Data.
 8.5. The policyholder and insured person agree that the insurer may use their personal data (name and contact information) to offer them additional insurance services and forward them information on the services of the insurer.
 8.6. The policyholder agrees that the insurer forwards their data to financial companies and their branches belonging to the same consolidation group with the insurer, in order to supply the policyholder with information and additional financial ser- vices based on their expected financial needs. Such financial companies are INSURANCE COMPANY Insurance SE (A. H. Tammsaare tee 47, 11316 Tallinn, tel. 610 6500, e-mail: info@Insurance Company.ee). The personal data to be forwarded includes the name and contact information of the person (address, phone number, e-mail ad- dress). The policyholder may withdraw their consent to the pro- cessing of the data at any time. The withdrawal of the consent does not have any retroactive effect. The policyholder has the right to prohibit the processing of data concerning them for the purpose of examining consumer habits or direct marketing.
  
 Note:- Mostly all the terms and conditions will be mentioned in policies which has received by policy holder as per different insurance plans in market.
 Procedure for settlement of disputes
  
 8.3. The policyholder may turn to a conciliation body at the Esto- nian Insurance Association to settle a dispute that the policy- holder has with the insurer. Before a conciliation procedure, the claim in the disputed matter shall be submitted to the in- surer and the insurer must be provided with an opportunity to reply to the claim. If the client is not satisfied with the reply from the insurer, they may turn to an insurance conciliation body (additional information on the web page of the Estonian Insurance Association www.eksl.ee).
 8.4. Any disputes arising from insurance contracts, including dis- putes concerning which no agreement is reached at an insur- ance conciliation body, shall be settled in Harju County Court. The policyholder has the right to file a complaint concerning the activities of the insurer with the Financial Supervision Authority.
  
  
 9. Use of foreign language documents
  
 9.1. Upon agreement between the parties, a translation into a foreign language may be attached to the Estonian-language documents of the insurance contract. The translation has an explanatory meaning only. Upon any inconsistencies between the translation and Estonian-language document, the Estoni- an-language document shall prevail.
 9.2. If it has been agreed that a foreign-language document (in- ternational clauses, etc.) is a part of the insurance contract, the Estonian-language translation of that document shall be appended to the insurance contract.
 1/5
 </p>
    </div>
  );
};

export default TermsAndConditions;
