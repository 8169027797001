// Component/LoadingIndicator.js
import React from 'react';
import './LoadingIndicator.css'; // Optional CSS file for styling

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <div className="loader"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingIndicator;
