import React from 'react'; 
import Services from '../Component/Services';
import About from '../Component/About';
import WhyUs from '../Component/WhyUs';
import Gallery from '../Component/Gallery';
import Carasoul from '../Component/Carasoul';
import ContactUs from '../Component/ContactUs';
import Enquiry from '../Component/Enquiry';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import './Home.css';
const Home = () => {
  

  return (
    <div>
      <Header />
      <div className="welcome-section">
        <h1 className="welcome-text">Welcome to JM Associate</h1>
      </div>
      <Services />
      <About />
      <WhyUs />
      <Gallery />
      <Carasoul />
      <Enquiry />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
