import React from 'react';
import './Gallery.css';
import galleryImage1 from './images/gallery1.jpeg'; // Replace with your actual image paths
import galleryImage2 from './images/gallery2.jpeg';
import galleryImage3 from './images/gallery3.jpeg';
import galleryImage4 from './images/gallery4.jpeg';
import galleryImage5 from './images/gallery5.jpeg';
import galleryImage6 from './images/gallery6.jpeg';
import galleryImage7 from './images/gallery7.jpeg';
import galleryImage8 from './images/gallery8.jpeg';

const Gallery = () => {
  const images = [
    { src: galleryImage1, alt: 'Gallery Image 1' },
    { src: galleryImage2, alt: 'Gallery Image 2' },
    { src: galleryImage3, alt: 'Gallery Image 3' },
    { src: galleryImage4, alt: 'Gallery Image 4' },
    { src: galleryImage5, alt: 'Gallery Image 5' },
    { src: galleryImage6, alt: 'Gallery Image 6' },
    { src: galleryImage7, alt: 'Gallery Image 7' },
    { src: galleryImage8, alt: 'Gallery Image 8' },
  ];

  return (
    <section className="gallery">
      <h2>Gallery</h2>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image.src} alt={image.alt} className="gallery-img" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
