import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Services from './Component/Services';
import About from './Component/About';
import WhyUs from './Component/WhyUs';
import Gallery from './Component/Gallery';
import Carasoul from './Component/Carasoul';
import ContactUs from './Component/ContactUs';
import LoadingIndicator from './Component/LoadingIndicator';
import PrivacyPolicy from './Component/PrivacyPolicy';
import RefundPolicy from './Component/RefundPolicy';
import TermsAndConditions from './Component/TermsAndConditions';
import CancellationPolicy from './Component/CancellationPolicy';
import Enquiry from './Component/Enquiry';
import Login from './Component/Login';
import Signup from './Component/Signup';
import Home from './Component/Home';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    // Simulate loading when location changes
    handleStart();
    const timeout = setTimeout(handleComplete, 500); // Adjust this duration as needed
    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <div className="App">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <main>
          <Routes>
            {/* Public Routes */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />

            {/* Private or Main Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/why-us" element={<WhyUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/carousel" element={<Carasoul />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cancellation-policy" element={<CancellationPolicy />} />
            
            {/* Redirect unknown paths to home */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      )}
    </div>
  );
}

// Wrap App with Router for routing capabilities
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
