import React from 'react'
import './TermsAndConditions.css';


export default function CancellationPolicy() {
  return (
    <div className="terms-container" >
      <h1>
      Cancellation Policy
</h1>
      <p>
      Step 1: Reach out to support team and inform them about your decision to terminate your health insurance policy.
Step 2: While informing our team, mention the policy ID of the plan you would like to end. You can find this number in your plan’s policy document. Also, clearly mention the reason you would like to end the plan.
Refunds- Once we receive your cancellation request, we will proceed (as per the applicable terms and conditions) with the cancellation and process the applicable amount that needs to be refunded to you. The refund will reflect in your bank account within 7 to 10 working days.
Note:  has a free-look period spanning 30 days (starting from the date of policy purchase). If a cancellation is initiated during this time, you will receive a full refund. After the free look period is over, refund will be initiated to you on a pro-rata basis.
If you are having a  insurance policy is not a choice, it’s a necessity.
In case you want to cancel the  insurance plan then there are certain rules, which you should be aware of. Although you can cancel your  insurance plan anytime, without having to serve a waiting period. The refund payable to you depends on when you’ve cancelled the policy. Read further to know in detail the cancellation policy of the  insurance plan.
Free-Look Cancellation

The insurance company offers a free-look period of 30 days from the date of policy initiation. During the free-look period of the policy, the insurance holder can cancel the policy if they are not satisfied with the terms and conditions of the policy. Under the free-look period, one will receive up to 100% of the premium amount. However, the insurer will deduct the premium of the number of days the person was covered, also any cost incurred by the insurer for the stamp duty and medical test, if any.
The  insurance policy can also be cancelled after the completion of the free-look period. However, if you cancel the policy after completion of the free-look period then you will not receive a 100% refund of the premium amount.
• In case of policy cancellation within 1 month after completion of the free-look period, 75% of the premium amount will be refunded to the policyholder.
• In case of policy cancellation within 3 months after completion of the free-look period, 50% of the premium amount will be refunded to the policyholder.
• In case of policy cancellation within 6 months after completion of the free-look period, 25% of the premium amount will be refunded to the policyholder.
• In case, one cancels the policy after completion of the 6 months of the policy, no refund will be paid by the insurer.
Cancellation Request & Process

On receiving the cancellation request made by you, the insurance company will contact you to share the reason for policy cancellation and will also suggest a possible solution. However, if you still want to cancel the  insurance policy online then the insurer will have to process the request and initiate the refund.
Steps to Cancel the  Insurance Policy
Now that you know the rules of  insurance policy cancellation, let’s take a look at the step by step process to cancel the policy.
• Get in touch with the support department of the insurance company to submit the request for policy cancellation.
• You will need to send a written request either offline or online with the details of your policy and the reason for the policy cancellation.
• After receiving the request for policy cancellation, the insurance company will try to give you a solution regarding the same.
• If you still want to cancel the policy, the process of policy cancelation will be initiated.
• Based on the tenure for which you were insured, the insurance company will deduct the premium amount and send you the refunds.
• An official cancellation letter/email will be sent to you by the insurer.
      </p>
    </div>
  )
}
