import React from 'react';
import './WhyUs.css';
import exceptionalStaffIcon from './images/staff.jpeg'; // Icon or image for each feature
import premiumQualityIcon from './images/quality.jpeg';
import affordablePricingIcon from './images/pricing.jpeg';
import highIndustryStandardsIcon from './images/industry.jpeg';

const WhyUs = () => {
  return (
    <section className="why-us">
      <h2>Why Us</h2>
      <div className="why-us-cards">r
        <div className="why-us-card">
          <img src={exceptionalStaffIcon} alt="Exceptional Staff" />
          <h3>Exceptional Staff</h3>
          <p>Our team consists of highly qualified and experienced professionals ready to assist you with the best services.</p>
        </div>
        <div className="why-us-card">
          <img src={premiumQualityIcon} alt="Premium Quality" />
          <h3>Premium Quality</h3>
          <p>We ensure that all our services are of the highest quality to meet the expectations of our clients.</p>
        </div>
        <div className="why-us-card">
          <img src={affordablePricingIcon} alt="Affordable Pricing" />
          <h3>Affordable Pricing</h3>
          <p>We offer the most competitive pricing while maintaining the best service standards in the industry.</p>
        </div>
        <div className="why-us-card">
          <img src={highIndustryStandardsIcon} alt="High Industry Standards" />
          <h3>High Industry Standards</h3>
          <p>Our work adheres to the highest industry standards, ensuring that you get top-notch services every time.</p>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
